import React, { Fragment, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

function isInteger(value) {
  return /^\d+$/.test(value);
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const BookingForm = () => {
  const { slotId } = useParams();
  const [leadDetails, setLeadDetails] = useState(null); // For storing lead details

  const [isEditingLead, setIsEditingLead] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const leadId = queryParams.get("lead"); // Get the lead ID from the URL
  const spid = queryParams.get("spid"); // Get the sales person ID from the URL
  const inviteEmail = queryParams.get("inviteEmail"); // Get the new email from the URL
  const deviation = queryParams.get("deviation"); // Get the deviation from the URL

  const [formData, setFormData] = useState({
    companyName: "",
    handsets: "",
    lines: "",
    monthlyExpenditure: "",
    contractMonthsRemaining: "",
    coach: "",
    industry: "",
    reschedule: "",
    listedDirector: "",
    contactRole: "",
    notes: "",
    spid: spid,
    slot: slotId,
    deviation: deviation,
    contactFirstName: "",
    contactLastName: "",
    currentSupplier: "", // Added for current supplier
  });

  const [contactRoles, setContactRoles] = useState([]);

  const handleCoachChange = (selectedCoach) => {
    setFormData((prevState) => ({
      ...prevState,
      coach: selectedCoach,
    }));
  };

  const handleIndustryChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      industry: value,
    }));
  };

  const handleRescheduleChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      reschedule: value,
    }));
  };

  const handleListedDirectorChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      listedDirector: value,
    }));
  };

  const handleContactRoleChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      contactRole: value,
    }));
  };

  const handleSupplierChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      currentSupplier: value, // Use "currentSupplier" in formData
    }));
  };

  const [isBooking, setIsBooking] = useState(false);

  const LoadingSpinner = () => {
    return (
      <div className="flex items-center justify-center">
        <div className="h-10 w-10 animate-spin rounded-full border-b-2 border-t-2 border-blue-900"></div>
      </div>
    );
  };

  const getRoleNameById = (id) => {
    const role = contactRoles.find((role) => role.id === id);
    return role ? role.name : null;
  };

  // Populate this state when leadDetails are fetched
  useEffect(() => {
    if (leadDetails) {
      setEditableFields((prevFields) => ({
        ...prevFields,
        editedCompanyName: leadDetails.Company,
        editedFirstName: leadDetails.First_Name || "",
        editedLastName: leadDetails.Last_Name,
        editedStreet: leadDetails.Street ?? "",
        editedCity: leadDetails.City ?? "",
        editedPostcode: leadDetails.Zip_Code,
      }));
    }
  }, [leadDetails]);

  // Fetch lead details when the component mounts
  useEffect(() => {
    const fetchLeadDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/leadDetails/${leadId}`,
          {
            withCredentials: true,
          },
        );

        setLeadDetails(response.data);

        if (response.data) {
          setLeadDetails(response.data);
          setFormData((prevFormData) => ({
            ...prevFormData,
            companyName: response.data.Company,
          }));
        }
      } catch (error) {
        console.error("Error fetching lead details:", error);
      }
    };

    fetchLeadDetails();
  }, [leadId]);

  //Function to format the address to avoid showing any empty fields
  function formatAddress(leadDetails) {
    if (!leadDetails) return "Loading...";

    const addressParts = [
      leadDetails.Street,
      leadDetails.City,
      leadDetails.Zip_Code,
    ];

    return addressParts.filter(Boolean).join(", ");
  }

  const [editableFields, setEditableFields] = useState({
    editedCompanyName: "",
    editedFirstName: "",
    editedLastName: "",
    editedStreet: "",
    editedCity: "",
    editedPostcode: "",
  });

  // Fetch contact roles when the component mounts
  useEffect(() => {
    const fetchContactRoles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getContactRoles`,
          {
            withCredentials: true,
          },
        );

        setContactRoles(response.data);
      } catch (error) {
        console.error("Error fetching contact roles:", error);
        if (error.response) {
          console.error("Error details:", error.response.data);
        }
      }
    };

    fetchContactRoles();
  }, []);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      companyName: editableFields.editedCompanyName,
    }));
  }, [editableFields.editedCompanyName]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contactFirstName: editableFields.editedFirstName,
    }));
  }, [editableFields.editedFirstName]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contactLastName: editableFields.editedLastName,
    }));
  }, [editableFields.editedLastName]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (
      [
        "handsets",
        "lines",
        "contractMonthsRemaining",
        "monthlyExpenditure",
      ].includes(name)
    ) {
      if (value === "" || isInteger(value)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else if (Object.keys(editableFields).includes(name)) {
      setEditableFields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) return;

    const inviteEmail2 = queryParams.get("inviteEmail");

    let payload = {
      ...formData,
      invitedEmail: inviteEmail2,
    };

    if (isEditingLead) {
      payload = {
        ...payload,
        companyName: editableFields.editedCompanyName,
        contactFirstName: editableFields.editedFirstName,
        contactLastName: editableFields.editedLastName,
        street: editableFields.editedStreet,
        city: editableFields.editedCity,
        postcode: editableFields.editedPostcode,
        invitedEmail: inviteEmail2,
        spid: spid,
        slot: slotId,
        deviation: deviation,
      };
    }

    setIsBooking(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/bookingForm/${leadId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(payload),
        },
      );

      if (response.status === 200) {
        Swal.fire({
          title: "Booking processed",
          text: "Your booking has been processed successfully.",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm button-custom",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.close();
          }
        });
      } else {
        toast.error("Error updating and converting the lead.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error updating and converting the lead.");
    } finally {
      setIsBooking(false);
    }
  };

  const isFormValid = () => {
    if (isEditingLead) {
      for (let field in editableFields) {
        if (!editableFields[field]) {
          alert(`Please fill out the ${field} field.`);
          return false;
        }
      }
    } else {
      for (let field in formData) {
        if (!formData[field]) {
          alert(`Please fill out the ${field} field.`);
          return false;
        }
      }
    }

    return true;
  };

  const coaches = [
    "TJ",
    "Charlie",
    "Diary Resched",
    "Farai",
    "Jordan",
    "Mike",
    "Other",
    "Uncoached",
  ];


  const currentSuppliers = [
    "3CX",
    "4Com",
    "5G Comms",
    "8x8",
    "9 Telecom",
    "ADW Telecom",
    "Aerial",
    "Aircall",
    "Apollo IT",
    "Arrow Comms",
    "Aspect Voice",
    "B4B Telecoms",
    "Babble",
    "Beanstalk",
    "Berry",
    "Blizzard Group",
    "bOnline",
    "BT Local Business",
    "BT Main",
    "CallSwitch",
    "Chess Telecoms",
    "circle cloud",
    "CircleLoop",
    "Claranet",
    "Cleartech Comms",
    "Colt",
    "Daisy",
    "DBFB",
    "Dialpad",
    "Digital Telecom",
    "Enreach",
    "Envera Telecoms",
    "Espria",
    "EVM",
    "Fairfield Telecom",
    "Fibre Telecoms",
    "FirstComm Europe",
    "Focus Group",
    "G4 Telecom",
    "Gemini",
    "Glide",
    "Global Telecom",
    "Horizon",
    "IceComms",
    "Jet Telecom",
    "K5 Communications",
    "Kcom",
    "Kinex",
    "LAN",
    "LilyCom",
    "Meraki",
    "Mint Technology",
    "Mtech Communications",
    "Network Telecom",
    "No system in place",
    "NSN",
    "O-bit Telecom",
    "OneCom",
    "Opus",
    "Other (Specify in notes)",
    "Peach",
    "Plan.com",
    "Plusnet",
    "PT Telecom",
    "Radius",
    "Redcare",
    "Rydal",
    "Sirus",
    "Sky",
    "Smart comms",
    "Southern Communications",
    "Southwest Comms",
    "Stratus Telecom",
    "SW Comms",
  ];

  const industries = [
    "Accounting",
    "Advertising",
    "Aerospace",
    "Agriculture",
    "Architecture",
    "Art",
    "Aviation",
    "Bar",
    "Beauty",
    "Biotechnology",
    "Bookshop",
    "Builders Merchants",
    "Business administration",
    "Charity",
    "Civil Engineering",
    "Cleaning",
    "Computers and information technology",
    "Construction",
    "Council",
    "Dentist",
    "Design",
    "Death",
    "Care",
    "Distribution",
    "Economics",
    "Education",
    "Electronics",
    "Engineering",
    "Entertainment",
    "Environmental protection",
    "Estate Agents",
    "Event Management",
    "Farming",
    "Finance",
    "Financial services",
    "Fire protection",
    "Food service",
    "Funeral director",
    "Health care",
    "Holding company",
    "Hospitality",
    "Human Resources",
    "Information and Communications Technology",
    "Insurance",
    "Intellectual property",
    "Investment",
    "Law",
    "Leisure",
    "Livestock",
    "Lobbying",
    "Logistics",
    "Mail",
    "Marine",
    "Management consulting",
    "Manufacturing",
    "Manufacturing engineering",
    "Marketing",
    "Media",
    "Medical device",
    "Medicine",
    "Mining",
    "Mortgage Advisor",
    "Motor vehicle",
    "Non-profit",
    "Nursing home",
    "Other - please specify in notes",
    "Pharmaceuticals",
    "Politics",
    "Printing",
    "Production",
    "Project Management",
    "Property Management",
    "Publishing",
    "Radio",
    "Recruitment",
    "Religion",
    "Removals",
    "Research",
    "Research and development",
    "Retail",
    "Sales",
    "Security",
    "Small business",
    "Sport",
    "Stock market",
    "Surveyor",
    "Technology",
    "Telecommunications",
    "Textiles",
    "Trade",
    "Transport",
    "Travel",
    "TV",
    "Utilities",
    "Valuation",
    "Waste Management",
    "Wind power",
  ];

  const reschedule = ["No", "Yes", "2nd Dem"];

  const listedDirector = ["Yes", "Correct person", "No", "Unknown", "Non Reg"];

  return (
    <div className="min-h-screen bg-gray-100 p-12">
      <div className="relative mx-auto w-full max-w-xl bg-white shadow sm:rounded-lg">
        <div className="px-4 py-6 sm:px-6">
          <div className="flex items-center justify-between">
            <h1 className="text-base font-semibold leading-7 text-gray-900">
              Customer details:
            </h1>
            <div className="group relative">
              <button
                onClick={() => setIsEditingLead(true)}
                className="m-1 rounded-md border border-transparent bg-blue-500 px-2 py-1 text-xs text-white hover:bg-blue-600"
              >
                Edit Customer
              </button>
              <div className="invisible absolute -bottom-10 left-1/2 z-10 mt-2 w-max -translate-x-1/2 transform rounded-md bg-black px-2 py-1 text-xs text-white opacity-0 transition-opacity duration-300 group-hover:visible group-hover:opacity-100">
                Complete the booking to save.
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Company name:
                  </dt>
                  <dd className="mt-1 flex items-center text-sm leading-6 text-gray-700 sm:col-span-2">
                    {isEditingLead ? (
                      <input
                        type="text"
                        name="editedCompanyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    ) : (
                      <>
                        <span>
                          {leadDetails ? leadDetails.Company : "Loading..."}
                        </span>
                      </>
                    )}
                  </dd>
                </div>

                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Contact name:
                  </dt>
                  <dd className="mt-1 flex flex-col gap-2 text-sm leading-6 text-gray-700 sm:col-span-2">
                    {isEditingLead ? (
                      <>
                        <input
                          type="text"
                          name="editedFirstName"
                          placeholder="First Name"
                          value={editableFields.editedFirstName}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <input
                          type="text"
                          name="editedLastName"
                          placeholder="Last Name"
                          value={editableFields.editedLastName}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </>
                    ) : (
                      <>
                        <span>
                          {leadDetails ? leadDetails.Full_Name : "Loading..."}
                        </span>
                      </>
                    )}
                  </dd>
                </div>

                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Address:
                  </dt>

                  <dd className="mt-1 flex flex-col gap-2 text-sm leading-6 text-gray-700 sm:col-span-2">
                    {isEditingLead ? (
                      <>
                        <input
                          type="text"
                          name="editedStreet"
                          placeholder="Street"
                          value={editableFields.editedStreet}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <input
                          type="text"
                          name="editedCity"
                          placeholder="City"
                          value={editableFields.editedCity}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <span>
                          {leadDetails.Zip_Code
                            ? leadDetails.Zip_Code
                            : "Loading..."}
                        </span>
                      </>
                    ) : (
                      <span>{formatAddress(leadDetails)}</span>
                    )}
                  </dd>
                </div>

                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Email sent to:
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">
                    <span>{inviteEmail ? inviteEmail : "Loading..."}</span>
                  </dd>
                </div>

                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"></div>
              </dl>
            </div>

            <h1 className="text-base font-semibold leading-7 text-gray-900">
              Booking details:
            </h1>

            {/* Handsets Input */}
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Handsets:
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">
                    <input
                      type="number"
                      step="1"
                      min="0"
                      name="handsets"
                      value={formData.handsets}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter number of handsets"
                    />
                  </dd>
                </div>
              </dl>
            </div>

            {/* Lines Input */}
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Lines:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">
                    <input
                      type="number"
                      step="1"
                      min="0"
                      name="lines"
                      value={formData.lines}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter number of lines"
                    />
                  </dd>
                </div>
              </dl>
            </div>

            {/* Contract (months remaining) */}
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Contract (months remaining):
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">
                    <input
                      type="number"
                      step="1"
                      min="0"
                      name="contractMonthsRemaining"
                      value={formData.contractMonthsRemaining}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter number of months remaining"
                    />
                  </dd>
                </div>
              </dl>
            </div>

            {/* Expenditure */}
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Monthly expenditure:
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">£</span>
                      </div>
                      <input
                        type="number"
                        name="monthlyExpenditure"
                        value={formData.monthlyExpenditure}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0"
                        min="0"
                        step="1"
                        aria-describedby="price-currency"
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span
                          className="text-gray-500 sm:text-sm"
                          id="price-currency"
                        >
                          GBP
                        </span>
                      </div>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>

            {/* Current Supplier Dropdown */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Current Supplier:
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">
                <div className="relative">
                  <Listbox
                    value={formData.currentSupplier}
                    onChange={(value) => handleSupplierChange(value)}
                  >
                    {({ open }) => (
                      <>
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <span className="block truncate">
                            {formData.currentSupplier || "Select supplier"}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {currentSuppliers.map((supplier, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9",
                                  )
                                }
                                value={supplier}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate",
                                      )}
                                    >
                                      {supplier}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4",
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </>
                    )}
                  </Listbox>
                </div>
              </dd>
            </div>

            {/* Coach Dropdown */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Coach:</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">
                <div className="relative">
                  <Listbox
                    value={formData.coach}
                    onChange={(value) => handleCoachChange(value)}
                  >
                    {({ open }) => (
                      <>
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <span className="block truncate">
                            {formData.coach || "Select coach"}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {coaches.map((coach, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9",
                                  )
                                }
                                value={coach}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate",
                                      )}
                                    >
                                      {coach}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4",
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </>
                    )}
                  </Listbox>
                </div>
              </dd>
            </div>

            {/* Industry Dropdown */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Industry:</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">
                <div className="relative">
                  <Listbox
                    value={formData.industry}
                    onChange={(value) => handleIndustryChange(value)}
                  >
                    {({ open }) => (
                      <>
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <span className="block truncate">
                            {formData.industry || "Select industry"}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {industries.map((industry, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9",
                                  )
                                }
                                value={industry}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate",
                                      )}
                                    >
                                      {industry}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4",
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </>
                    )}
                  </Listbox>
                </div>
              </dd>
            </div>

            {/* Reschedule Dropdown */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Reschedule:</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">
                <div className="relative">
                  <Listbox
                    value={formData.reschedule}
                    onChange={(value) => handleRescheduleChange(value)}
                  >
                    {({ open }) => (
                      <>
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <span className="block truncate">
                            {formData.reschedule || "Select"}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {reschedule.map((rescheduleOption, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9",
                                  )
                                }
                                value={rescheduleOption}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate",
                                      )}
                                    >
                                      {rescheduleOption}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4",
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </>
                    )}
                  </Listbox>
                </div>
              </dd>
            </div>

            {/* Listed Director Dropdown */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Listed Director?
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">
                <div className="relative">
                  <Listbox
                    value={formData.listedDirector}
                    onChange={(value) => handleListedDirectorChange(value)}
                  >
                    {({ open }) => (
                      <>
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <span className="block truncate">
                            {formData.listedDirector || "Select"}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {listedDirector.map((director, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9",
                                  )
                                }
                                value={director}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate",
                                      )}
                                    >
                                      {director}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4",
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </>
                    )}
                  </Listbox>
                </div>
              </dd>
            </div>

            {/* Contact Role Dropdown */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">
                Contact Role:
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">
                <div className="relative">
                  <Listbox
                    value={formData.contactRole}
                    onChange={(value) => handleContactRoleChange(value)}
                  >
                    {({ open }) => (
                      <>
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <span className="block truncate">
                            {getRoleNameById(formData.contactRole) ||
                              "Select Role"}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {contactRoles.map((role) => (
                              <Listbox.Option
                                key={role.id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9",
                                  )
                                }
                                value={role.id}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate",
                                      )}
                                    >
                                      {role.name}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4",
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </>
                    )}
                  </Listbox>
                </div>
              </dd>
            </div>

            {/* Notes */}
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Notes:</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">
                    <textarea
                      name="notes"
                      value={formData.notes}
                      onChange={handleChange}
                      rows="4"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter notes for booking"
                    ></textarea>
                  </dd>
                </div>
              </dl>
            </div>

            {isBooking ? (
              <LoadingSpinner />
            ) : (
              <button
                type="submit"
                className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <CheckCircleIcon
                  className="-ml-0.5 h-5 w-5"
                  aria-hidden="true"
                />
                Book
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookingForm;
